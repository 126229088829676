import {Controller} from "@hotwired/stimulus"
import axios from "axios";
import getCSRFToken from '@shopify/csrf-token-fetcher';

export default class extends Controller {

    connect() {
        const fav_btn = this.element.getElementsByClassName('favorite-marker')[0]
        fav_btn.addEventListener('click', () => this.toggle_favorite(fav_btn))

        // const check_btn = this.element.getElementsByClassName('checkmark-icon')[0]
        // check_btn.addEventListener('click', () => this.toggle_check_button(check_btn))
        //
        // this.add_open_dropdown_listeners()
    }

    toggle_favorite(fav_btn) {
        axios.patch(
            `/projects/${this.element.getAttribute('data-project-id')}/toggle_favorite`,
            {authenticity_token: getCSRFToken()}
        ).then(function (response) {
            if (response.data === 'inactive') {
                location.reload()
                //fav_btn.classList.add('active')
            // } else {
            //     fav_btn.classList.remove('active')
            }
        })
    }

    // toggle_check_button(btn) {
    //     btn.classList.toggle('checked')
    // }
    //
    // add_open_dropdown_listeners() {
    //     const cell = this.element
    //     const dropdown = cell.getElementsByClassName('dropdown-button')[0]
    //     dropdown.addEventListener('before-open-panel', () => cell.classList.add('active'))
    //     dropdown.addEventListener('after-close-panel', () => cell.classList.remove('active'))
    // }
}