import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.add_open_dropdown_listeners()
    }

    add_open_dropdown_listeners() {
        const dropdown = this.element.getElementsByClassName('dropdown-button')[0]
        dropdown.addEventListener('before-open-panel', () => this.element.classList.add('active'))
        dropdown.addEventListener('after-close-panel', () => this.element.classList.remove('active'))
    }
}